import { Component, OnInit } from '@angular/core';
import { DepartureService } from 'src/app/services/departure.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { forkJoin } from 'rxjs';
import { Departure } from 'src/app/classes/departure';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { Settings } from 'src/app/classes/settings';
import { StopPointService } from 'src/app/services/stop-point.service';
import { AccountLoginResponse } from 'src/app/classes/account_login';
import { SharedInfoComponent } from '../../shared/shared-info/shared-info.component';

@Component({
  selector: 'app-departures-display',
  templateUrl: './departures-display.component.html',
  styleUrls: ['./departures-display.component.less']
})
export class DeparturesDisplayComponent implements OnInit {

  _loading = false;

  stop_point_id: string = null;
  stop_name: string = null;
  carrier_id: string = null;
  settings: Settings = null;
  number_of_deps = -1;
  acc_info: AccountLoginResponse = null;

  _label_close = "Zamknij";
  _label_header = "Odjazdy dla";
  _label_line = "Linia";
  _label_direction = "Kierunek";
  _label_time = "Czas";
  _label_favorite = "Dodaj przystanek do ulubionych";
  _label_refresh = "Odśwież";
  _label_no_deps = "brak odjazdów na dziś";

  public dataSource: MatTableDataSource<Departure> = new MatTableDataSource<Departure>([]);

  displayedColumns: string[] = ['line', 'direction', 'time'];

  constructor(private _departureService: DepartureService,
    private _stopPointService: StopPointService,
    private _favoritesService: FavoritesService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.settings = JSON.parse(localStorage.getItem("_pass_settings"));
    this.acc_info = JSON.parse(localStorage.getItem("_pass_acc_info"));
    this.updateLabels();

    this.stop_point_id = localStorage.getItem("_pass_deps_stop_point_id");
    this.stop_name = localStorage.getItem("_pass_deps_stop_name");
    this.carrier_id = localStorage.getItem("_pass_carrier_id");

    this.loadDepartures();
  }

  loadDepartures() {
    this._loading = true;
    this.dataSource.data = [];
    forkJoin([
      this._departureService.findNearest(this.carrier_id, this.stop_point_id)
    ]).subscribe(results => {
      var odjazdy = results[0];
      // if (odjazdy.length == 0) {
        // var o1 = new Departure();
        // o1.line_name = "1";
        // o1.direction = "Czerniakowska";
        // o1.time_as_string = "<1min";
        // odjazdy.push(o1);
        // var o2 = new Departure();
        // o2.line_name = "12";
        // o2.direction = "Podolska";
        // o2.time_as_string = "12min";
        // odjazdy.push(o2);
        // var o3 = new Departure();
        // o3.line_name = "34";
        // o3.direction = "Warszawska";
        // o3.time_as_string = "20:34";
        // odjazdy.push(o3);
      // }

      console.log("ODJAZDY", odjazdy);

      // let temp = [];
      // results[0].forEach(d => {
        // if (d.stop_point_id) {
          // temp.push(d);
        // }
      // });
      this.dataSource.data = odjazdy;
      this.number_of_deps = odjazdy.length;
      this._loading = false;
    })
  }

  refresh() {
    this.loadDepartures();
  }

  addToFavorite() {
    if (this.stop_point_id == null) {
      return;
    }

    this._stopPointService.findOne(this.stop_point_id).subscribe(result => {
      this._favoritesService.addStopPoint(result);
      this.dialog.open(SharedInfoComponent, {
        maxWidth: '500px',
        data: {
          question: 'Dodano przystanek do ulubionych'
        }
      });
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateLabels() {
    if (this.settings.language == "pl") {
      this._label_close = "Zamknij";
      this._label_header = "Odjazdy dla";
      this._label_line = "Linia";
      this._label_direction = "Kierunek";
      this._label_time = "Czas";
      this._label_favorite = "Dodaj do ulubionych";
      this._label_refresh = "Odśwież";
      this._label_no_deps = "brak odjazdów na dziś";
    } else if (this.settings.language == "en") {
      this._label_close = "Close";
      this._label_header = "Departures for";
      this._label_line = "Line";
      this._label_direction = "Direction";
      this._label_time = "Time";
      this._label_favorite = "Add to favorites";
      this._label_refresh = "Refresh";
      this._label_no_deps = "no departures for today";
    } else {
      this._label_close = "Ende";
      this._label_header = "Abfahrten von";
      this._label_line = "Linien";
      this._label_direction = "Direktion";
      this._label_time = "Zeit";
      this._label_favorite = "Fügen zu Ihren Favoriten";
      this._label_refresh = "Auffrisch";
      this._label_no_deps = "Keine Abfahrten für heute";
    }
  }

}
