import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import * as L from 'leaflet';

import { MatDialog } from '@angular/material';
import { Settings } from './classes/settings';
import { SettingsComponent } from './components/settings/settings.component';
import { LineListComponent } from './components/line/line-list/line-list.component';
import { ChangeCarrierComponent } from './components/carrier/change-carrier/change-carrier.component';
import { StopPointService } from './services/stop-point.service';
import { interval, forkJoin } from 'rxjs';
import { StopPoint } from './classes/stop_point';
import { PassengerMessageService } from './services/passenger-message.service';
import { FavoritesListComponent } from './components/favorites/favorites-list/favorites-list.component';
import { DeparturesDisplayComponent } from './components/departures/departures-display/departures-display.component';
import { VehicleService } from './services/vehicle.service';
import {
  DeparturesTimetableComponent
} from './components/departures/departures-timetable/departures-timetable.component';
import { PassengerImageService } from './services/passenger-image.service';
import { PassengerImage } from './classes/passenger-image';
import { StopPointListComponent } from './components/stop/stop-point-list/stop-point-list.component';
import { RegisterComponent } from './components/account/register/register.component';
import { LoginComponent } from './components/account/login/login.component';
import { AccountLoginResponse } from './classes/account_login';
import { CityObjectService } from './services/city-object.service';
import {
  DeparturesRailwayNearestComponent
} from './components/departures/departures-railway-nearest/departures-railway-nearest.component';
import { RulesComponent } from "./components/rules/rules.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  env = environment;

  map: any;
  sub: any;
  vehic_sub: any;
  img_sub: any;
  co_sub: any;

  logged: boolean = false;
  _pass_acc_info: AccountLoginResponse = null;

  act_msg: string = "";
  images: PassengerImage[] = [];
  act_img: string = "";
  act_img_index = -1;
  duration_passed = 0;
  duration_upd = 1000;
  settings: Settings = new Settings();
  carrier_selected: string = null;
  _label_lang = "Język";
  _label_carrier_choose = "Wybierz przewoźnika";
  _label_lines = "Linie";
  _label_line = "Linia";
  _label_direction = "Kierunek";
  _label_stops = "Przystanki";
  _label_favorites = "Ulubione";
  _label_nearest = "Najbliższe";
  _label_timetable = "Rozkład";
  _label_fast = "Przyspieszony";
  _label_good = "O czasie";
  _label_delay = "Opóźniony";
  _label_logout = "Wyloguj";
  _label_login = "Zaloguj";
  _label_register = "Rejestracja";
  _label_rules = "Regulamin"
  stops: StopPoint[] = [];
  stopPointLayer;
  vehicleLayer;
  cityObjectLayer;
  private oDialog = undefined;



  constructor(public dialog: MatDialog,
    private _stopPointService: StopPointService,
    private _messageService: PassengerMessageService,
    private _imageService: PassengerImageService,
    private _vehicleService: VehicleService,
    private _cityObjectService: CityObjectService) {
  }

  ngOnInit(): void {
    this.map = new L.Map('map', {
      zoomControl: true,
      maxZoom: 19,
      minZoom: 12,
      center: new L.LatLng(environment.map_center[0], environment.map_center[1]),
      zoom: environment.map_zoom,
    });

    const tileLayers = {
      'Layer': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxNativeZoom: 19,
        zIndex: 0,
        maxZoom: 19,
        bounds: L.latLngBounds(environment.map_sw, environment.map_ne),
      }).addTo(this.map)
    };
    L.control.layers(tileLayers, null, { collapsed: false }).addTo(this.map);

    this.stopPointLayer = L.layerGroup().addTo(this.map);
    this.vehicleLayer = L.layerGroup().addTo(this.map);
    this.cityObjectLayer = L.layerGroup().addTo(this.map);

    if (!localStorage.getItem("_pass_settings")) {
      let set = new Settings();
      set.language = "pl";
      localStorage.setItem("_pass_settings", JSON.stringify(set));
    }

    this.settings = JSON.parse(localStorage.getItem("_pass_settings"));
    this._pass_acc_info = JSON.parse(localStorage.getItem("_pass_acc_info"));
    this.logged = this._pass_acc_info != null;

    this.carrier_selected = localStorage.getItem("_pass_carrier_id");
    if (this.env.one_carrier_only) {
      this.carrier_selected = "1";
      localStorage.setItem("_pass_carrier_id", "1");
    }

    this.updateLabels();

    this._stopPointService.findAll(this.carrier_selected).subscribe(res => {
      this.stops = res;
      console.log("przystanki", this.stops)
      this.drawStopPoints();
    });

    this.sub = interval(5000)
      .subscribe((val) => {
        this.getSpecialMessages();
      });

    this.img_sub = interval(this.duration_upd)
      .subscribe((val) => {
        this.updateImage();
      });

    this.vehic_sub = interval(5000)
      .subscribe((val) => {
        this.getMovingVehicles();
      });

    this.co_sub = interval(5000)
      .subscribe((val) => {
        this.getCityObjects();
      });
  }

  getSpecialMessages() {
    let carrier_id = localStorage.getItem("_pass_carrier_id");

    this._messageService.findActual(carrier_id).subscribe(r => {
      let s = "";
      let i = 0;
      r.forEach(m => {
        s += m.content;
        if (i < r.length - 1) {
          s += "   |   ";
        }
        i++;
      });

      this._imageService.findActual(carrier_id).subscribe(r2 => {
        if (this.imgsDiff(r2)) {
          this.images = [];
          this.images = r2;
          this.act_img_index = 0;
        }

        if (r2.length > 0) {
          this.act_msg = "";
        } else {
          this.act_msg = s;
        }
      });
    });
  }

  updateImage() {
    //console.log("upda img");


    if (this.images.length == 0) {
      //console.log("Brak obrazow");
      return;
    }

    this.act_msg = "";

    let index_before = this.act_img_index;
    this.duration_passed += this.duration_upd;

    // Zmiana poczatkowego stanu
    if (this.act_img_index == -1) {
      this.act_img_index++;
    }

    if (this.duration_passed >= this.images[this.act_img_index].duration) {
      this.act_img_index++;
      this.duration_passed = 0;
    }

    if (this.act_img_index >= this.images.length) {
      this.act_img_index = 0;
    }

    console.log("index przed: " + index_before);
    console.log("index po: " + this.act_img_index);
    console.log(this.images[this.act_img_index].duration);

    let path = environment.server_url + 'assets/media/' + this.images[this.act_img_index].image.filename;

    this.act_msg = "";
    if (this.act_img != path) {
      this.act_img = path;
    }
  }

  imgsDiff(new_imgs: PassengerImage[]) {
    let ret = false;

    if (this.images.length != new_imgs.length) {
      console.log("rozny rozmiar lisit, przed: " + this.images.length + ", po: " + new_imgs.length);
      ret = true;
    } else {
      for (let i = 0; i < this.images.length; i++) {
        if (this.images[i].image_id != new_imgs[i].image_id) {
          ret = true;
          break;
        }
      }
    }

    return ret;
  }

  drawStopPoints() {
    // let test: StopPoint = {
    //   id: "1",
    //   name: "Test_name",
    //   code: "1",
    //   symbol: "1",
    //   lat: 50.04275807922043,
    //   lon: 22.006045133971295,
    // }
    this.stopPointLayer.clearLayers();
    this.stops.forEach(p => {
      console.log("PRZYSTANEK:", p);
      if (p.lat != null && p.lon != null) {
        var circle;
        if (p.railway) {
          circle = L.marker([p.lat, p.lon], {
            color: 'blue',
            fillColor: 'blue',
            fillOpacity: 0.7,
            radius: 10,
            icon: environment.railwayIcon
          });
        } else {
          circle = L.marker([p.lat, p.lon], {
            color: 'blue',
            fillColor: 'blue',
            fillOpacity: 0.7,
            radius: 10,
            icon: environment.stopIcon
          });
        }

        var container = L.DomUtil.create('div');
        let header = this.createHeader(p.name, container);
        this.createBr(container);
        let nearest_button = this.createButton(this._label_nearest, container);
        this.createBr(container);
        let timetable_button = this.createButton(this._label_timetable, container);
        //this.createBr(container);
        //let names_button = this.createButton("Pokaż nazwy", container);


        var new_popup = L.popup({ closeButton: true, autoClose: false, closeOnClick: false, autoPan: false })
          .setLatLng([p.lat, p.lon])
          .setContent(
            container
          );

        L.DomEvent.on(nearest_button, 'click', () => {
          this.stopClick(p, self);
        });

        L.DomEvent.on(timetable_button, 'click', () => {
          this.stopClick2(p, self);
        });

        circle.bindPopup(new_popup);

        circle.bindTooltip(p.name, {
          direction: 'top',
          offset: [0, -30],
          permanent: true,
          sticky: true,
          interactive: true,
          opacity: 0.8
        });

        let self = this;
        circle.addTo(this.stopPointLayer);
      }
    });
  }

  createHeader(label: string, container: any) {
    var btn = L.DomUtil.create('b', '', container);
    btn.innerHTML = label;
    return btn;
  }

  createButton(label: string, container: any) {
    var btn = L.DomUtil.create('button', '', container);
    //btn.setAttribute('type', 'button');
    btn.setAttribute('color', 'primary');
    btn.setAttribute('mat-flat-button', 1);
    btn.setAttribute('class', 'mat-flat-button');
    btn.innerHTML = label;
    return btn;
  }

  createBr(container: any) {
    var btn = L.DomUtil.create('br', '', container);
    return btn;
  }

  setLang(lang: string) {
    this.settings.language = lang;
    localStorage.setItem("_pass_settings", JSON.stringify(this.settings));
    this.updateLabels();
  }

  stopClick(stop: StopPoint, self) {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    console.log("clock", stop.railway)
    localStorage.setItem("_pass_deps_stop_point_id", stop.id);
    localStorage.setItem("_pass_deps_stop_name", stop.displ_name);
    if (stop.railway) {
      self.dialog.open(DeparturesRailwayNearestComponent, {
        width: '100vw',
        height: '100vh'
      });
    } else {
      self.dialog.open(DeparturesDisplayComponent, {
        width: '100vw',
        height: '100vh'
      });
    }
  }

  stopClick2(stop: StopPoint, self) {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    localStorage.setItem("_pass_deps_stop_point_id", stop.id);
    localStorage.setItem("_pass_deps_stop_name", stop.displ_name);
    if (stop.railway) {
      self.dialog.open(DeparturesRailwayNearestComponent, {
        // self.dialog.open(DeparturesRailwayComponent, {
        width: '100vw',
        height: '100vh'
      });
    } else {
      self.dialog.open(DeparturesTimetableComponent, {
        width: '100vw',
        height: '100vh'
      });
    }

  }

  getMovingVehicles() {
    this._vehicleService.findMovingVehicles().subscribe(result => {
      this.vehicleLayer.clearLayers();
      result.forEach(v => {
        if (v.lat != null && v.lon != null) {
          let pop_s = "";
          let d_pop = true;
          let cl = "";
          var dod = true
          var dev = v.deviation;


          if (v.direction.startsWith("-")) {
            // dod = false
          } else if (dev >= -59 && dev <= 119) {
            pop_s = this._label_good;
            cl = "popup_good";
          } else if (dev <= -60) {
            pop_s = this._label_fast + " " + Math.round(-dev / 60) + " min";
            cl = "popup_fast";
          } else if (dev >= 120) {
            pop_s = this._label_delay + " " + Math.round(dev / 60) + " min";
            cl = "popup_delayed";
          }

          var new_popup = L.popup({ closeButton: false, autoClose: false, closeOnClick: false, autoPan: false })
            .setLatLng([v.lat, v.lon])
            .setContent(
              this._label_line + ": <b>" + v.line + "</b><br/>" +
              this._label_direction + ": <b>" + v.direction + "</b><br/>" +
              (d_pop ? pop_s : "")
            );

          var popupOptions =
          {
            'className': cl
          }

          if (dod) {

            let marker = L.marker([v.lat, v.lon], {
              icon: environment.busIcon
            })
              .bindPopup(new_popup, popupOptions);

            const self = this;
            //marker.on('click', (e: L.LeafletMouseEvent) => this.vehicleClick(v, self));
            marker.addTo(this.vehicleLayer);
            marker.openPopup();
          }
        }
      });
    });
  }

  getCityObjects() {
    this._cityObjectService.findAll().subscribe(result => {
      console.log("CO: " + result)
      this.cityObjectLayer.clearLayers();
      result.forEach(v => {

        var new_popup = L.popup({ closeButton: false, autoClose: false, closeOnClick: false, autoPan: false })
          .setLatLng([v.lat, v.lon])
          .setContent();

        var popupOptions =
        {
          'className': ''
        }

        var icon;
        if (v.category == 0) {
          icon = environment.infoboxIcon;
        } else if (v.category == 1) {
          icon = environment.hospitalIcon;
        } else if (v.category == 2) {
          icon = environment.bankIcon;
        } else if (v.category == 3) {
          icon = environment.tvmIcon;
        }

        let marker = L.marker([v.lat, v.lon], {
          icon: icon
        });

        const self = this;
        marker.addTo(this.cityObjectLayer);
      });
    });
  }

  updateLabels() {
    if (this.settings.language == "pl") {
      this._label_lang = "Język";
      this._label_carrier_choose = "Wybierz przewoźnika";
      this._label_lines = "Linie";
      this._label_stops = "Przystanki";
      this._label_favorites = "Ulubione";
      this._label_nearest = "Najbliższe";
      this._label_timetable = "Rozkład";

      this._label_delay = "Opóźniony";
      this._label_good = "O czasie";
      this._label_fast = "Przyspieszony";
      this._label_line = "Linia";
      this._label_direction = "Kierunek";

      this._label_login = "Zaloguj";
      this._label_register = "Rejestracja";

    } else if (this.settings.language == "en") {
      this._label_lang = "Language";
      this._label_carrier_choose = "Choose carrier";
      this._label_lines = "Lines";
      this._label_stops = "Stops";
      this._label_favorites = "Favorites";
      this._label_nearest = "Nearest";
      this._label_timetable = "Timetable";

      this._label_delay = "Delayed";
      this._label_good = "On time";
      this._label_fast = "Early";
      this._label_line = "Line";
      this._label_direction = "Direction";

      this._label_login = "Sign in";
      this._label_register = "Sign up";

    } else {
      this._label_lang = "Sprache";
      this._label_carrier_choose = "Betreiberauswahl";
      this._label_lines = "Linien";
      this._label_stops = "Bushaltestellen";
      this._label_favorites = "Favoriten";
      this._label_nearest = "Bevorstehende Abfahrten";
      this._label_timetable = "Fahrplan";

      this._label_delay = "Spät";
      this._label_good = "Pünktlich";
      this._label_fast = "Beschleunigt";
      this._label_line = "Linien";
      this._label_direction = "Direktion";

      this._label_login = "Login";
      this._label_register = "Regiestrieren";
    }

    if (this.carrier_selected) {
      this._label_carrier_choose = this.carrier_selected == "1" ? "MZK" : "FENIKS";
      this.drawStopPoints();
    }
  }

  clickCarrier() {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    this.oDialog = this.dialog.open(ChangeCarrierComponent, {
      width: '100vw',
      height: '100vh'
    });
    this.oDialog.afterClosed().subscribe(result => {
      this.carrier_selected = localStorage.getItem("_pass_carrier_id");
      if (this.carrier_selected) {
        this._label_carrier_choose = this.carrier_selected == "1" ? "MZK" : "FENIKS";
        this.drawStopPoints();
      }
    });
  }

  clickFavorites() {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    this.oDialog = this.dialog.open(FavoritesListComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  clickLine() {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    this.oDialog = this.dialog.open(LineListComponent, {
      width: '100vw',
      height: '100vh'
    });
  }

  clickStop() {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    this.oDialog = this.dialog.open(StopPointListComponent, {
      width: '100vw',
      height: '100vh'
    });
  }
  clickRules() {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    this.oDialog = this.dialog.open(RulesComponent, {
      width: '40%',
      height: '80%'
    });
  }

  clickSettings() {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    this.oDialog = this.dialog.open(SettingsComponent);

  }

  clickLogin() {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    this.oDialog = this.dialog.open(LoginComponent);
    this.oDialog.afterClosed().subscribe(result => {
      this._pass_acc_info = JSON.parse(localStorage.getItem("_pass_acc_info"));
      this.logged = this._pass_acc_info != null;
    });
  }

  clickRegister() {
    if (this.oDialog != undefined) {
      this.oDialog.close();
    }
    this.oDialog = this.dialog.open(RegisterComponent);
    this.oDialog.afterClosed().subscribe(result => {
      this._pass_acc_info = JSON.parse(localStorage.getItem("_pass_acc_info"));
      this.logged = this._pass_acc_info != null;
    });
  }

  clickLogout() {
    if (!this.logged) return;

    this.logged = false;
    localStorage.setItem("_pass_acc_info", null);
    this._pass_acc_info = null;
  }

}
