import { HttpClient } from '@angular/common/http';
import { CityObject } from '../classes/city-object';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CityObjectService  {
  
  constructor(private _http: HttpClient) { }

  findAll(): Observable<CityObject[]> {
    return this._http.get<CityObject[]>(
      environment.api_url + "pass/cityobjs")
  }

}
