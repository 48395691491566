import { Component, OnInit, } from '@angular/core';
@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.less']
})
export class RulesComponent implements OnInit {

  _label_close = "Zamknij";
  _label_header = "Regulamin";


  constructor() { }

  ngOnInit() {}

}
