import { latLng, icon } from "leaflet";

export const environment = {
  production: true,

  map_sw: latLng(49.042159, 20.156575),
  map_ne: latLng(51.042159, 24.712824),
  map_center: [50.04275807922043, 22.006045133971295],
  map_zoom: 19,
  logo: "",

  line_module: true,

  // api_url: 'http://192.168.55.90:8080/api/',
  api_url: 'https://rck.erzeszow.pl:7443/szd/',
  server_url: 'http://rck.erzeszow.pl/',
  carrierSelectGuard: true,

  languages_de: true,
  one_carrier_only: true,

  stopIcon: icon({
    iconUrl: 'assets/images/bus1.png',
    iconSize: [64, 64]
  }),

  railwayIcon: icon({
    iconUrl: 'assets/images/railway.png',
    iconSize: [64, 64]
  }),

  busIcon: icon({
    iconUrl: 'assets/images/32x32.png',
    iconSize: [64, 64]
  }),

  infoboxIcon: icon({
    iconUrl: 'assets/images/info.png',
    iconSize: [64, 64]
  }),

  hospitalIcon: icon({
    iconUrl: 'assets/images/hospital.jpg',
    iconSize: [64, 64]
  }),

  bankIcon: icon({
    iconUrl: 'assets/images/bank.png',
    iconSize: [64, 64]
  }),

  tvmIcon: icon({
    iconUrl: 'assets/images/tvm.png',
    iconSize: [64, 64]
  }),

  carrier_name_overwrite: "RCK",

  gis: false

};
